<template>
  <div class="buildings">
    <MobileTable
      v-model:selection="store.state.user.selectedUsers"
      :label="$route.name"
      :columns="user.columns"
      :data="store.state.user.users"
    />
    <DesktopTable
      v-model:selection="store.state.user.selectedUsers"
      :label="$route.name"
      :columns="user.columns"
      :data="store.state.user.users"
    />
    <Actions :items="user.actions" />
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import { onBeforeMount, reactive } from "vue";
import store from "../../store";
import { openMode, actions } from "../../constants";

export default {
  components: { MobileTable, DesktopTable, Actions },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onCreate = () => {
      store.commit("setOpenMode", openMode.CREATE);
      router.push("/usuarios/nuevo");
    };
    const onModify = () => {
      if (store.state.user.selectedUsers.length !== 1) {
        alert("Debe seleccionar un registro!");
      } else {
        store.commit("setOpenMode", openMode.MODIFY);
        router.push("/usuarios/modificar");
      }
    };
    const onChangeStatus = async (status) => {
      if (!store.state.user.selectedUsers.length) {
        alert("Debe seleccionar un registro!");
      } else {
        store.commit("setLoading", true);

        const users = store.state.user.selectedUsers.map((item) => item.userId);
        if (users.includes(store.state.auth.user.id)) {
          alert("No se puede modificar el usuario que ha iniciado sesión!");
          return;
        }

        const response = await store.dispatch(actions.userActions.status, {
          users,
          status,
        });

        if (response.ok) {
          store.commit("setSelectedUsers", []);
          await store.dispatch(actions.userActions.findAll);
          store.commit("setLoading", false);
        } else {
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response.message,
            life: 5000,
          });
          store.commit("setLoading", false);
        }
      }
    };

    const user = reactive({
      columns: [
        {
          field: "company",
          header: "Administradora",
          sort: true,
        },
        {
          field: "name",
          header: "Usuario",
          sort: true,
        },
        {
          field: "identity",
          header: "Rut / CI",
        },
        {
          field: "email",
          header: "Email",
        },
        {
          field: "phone",
          header: "Teléfono",
        },
        {
          field: "active",
          header: "Habilitado",
        },
      ],
      actions: [
        {
          name: "Nuevo",
          action: onCreate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/usuarios/nuevo"),
        },
        {
          name: "Modificar",
          action: onModify,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/usuarios/modificar"),
        },
        {
          name: "Habilitar",
          action: () => onChangeStatus(true),
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/usuarios/modificar"),
        },
        {
          name: "Deshabilitar",
          action: () => onChangeStatus(false),
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/usuarios/modificar"),
        },
      ],
    });

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedUsers", []);
      const finded = store.state.general.breadcrumbs.find(
        (item) => item.to === route.path
      );
      if (!finded)
        store.commit("addBreadcrumb", { label: route.name, to: route.path });
      await store.dispatch(actions.userActions.findAll);
      store.commit("setLoading", false);
    });

    return { store, user };
  },
};
</script>

<style scoped></style>
